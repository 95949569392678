/*****************************
  Countdown
*****************************/
.coming-soon {
	h1 {
		font-size: 110px;
		font-weight: 500;
	}

}

.countdown {
	display: inline-block;
	text-align: center;
	margin: 0px 20px;

	span {
		font-size: 46px;
		line-height: 56px;
		font-weight: 300;
		color: $primary;
	}

	p {
		font-size: 20px;
		text-transform: capitalize;
		margin-bottom: 0;
		color: $gray-8;
	}

}

@media (max-width:1024px) {
	.coming-soon {
		h1 {
			font-size: 80px;
		}

	}

}

@media (max-width:991px) {
	.coming-soon {
		height: 100% !important;
		padding: 60px 0 !important;
	}

}

@media (max-width:767px) {
	.coming-soon {
		h1 {
			font-size: 60px;
		}

	}

}

@media (max-width:575px) {
	.coming-soon {
		h1 {
			font-size: 40px;
		}

	}

}
