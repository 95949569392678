/*****************************
  Button
*****************************/

/* btn */
.btn {
	font-size: 14px;
	font-weight: 500;
	padding: 16px 34px;
	line-height: 1.4;
	border-radius: $border-radius;
	position: relative;
	transition: all 0.5s ease-in-out;

	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none;
				outline: none;
			}

		}

		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none;
					outline: none;
					color: $gray-9;
				}

			}

		}

	}

	&:hover {
		outline: none;
		transform: translateY(-3px);
		box-shadow: $box-shadow;
	}

	&:focus {
		box-shadow: none;
		outline: none;
		color: $white;
	}

	&:active {
		box-shadow: none;
		outline: none;
		color: $white;
	}

}

button {
	outline: medium none !important;
	color: $primary;
}

.btn-link:after {
	margin-left: 12px;
}

.btn.btn-link {
	&:hover {
		box-shadow: none;
	}

}

.show {
	>.btn.dropdown-toggle {
		&:focus {
			box-shadow: none;
			outline: none;
			color: $white;
		}

	}

	>.btn-primary.dropdown-toggle {
		background: $primary;
		border-color: $primary;
	}

}

.btn-primary {
	background: $primary;
	border-color: $primary;

	&:hover {
		background: darken($primary, 10%);
		border-color: darken($primary, 10%);

		&:not(:disabled) {
			&:not(.disabled).active {
				background: darken($primary, 10%);
				border-color: darken($primary, 10%);
			}

			&:not(.disabled) {
				&:active {
					background: darken($primary, 10%);
					border-color: darken($primary, 10%);
				}

			}

		}

	}

	&:active {
		background: darken($primary, 10%);
		border-color: darken($primary, 10%);
	}

	&:focus {
		background: darken($primary, 10%);
		border-color: darken($primary, 10%);
	}

}

.btn-primary:not(:disabled):not(.disabled):active:focus {
	background: $primary;
	border-color: $primary;
}

.btn-dark {
	background: $gray-9;
	border-color: $gray-9;

	&:hover {
		background: darken($gray-9, 10%);
		border-color: darken($gray-9, 10%);
	}

	&:focus {
		background: darken($gray-9, 10%);
		border-color: darken($gray-9, 10%);
	}

	&:active {
		background: darken($gray-9, 10%);
		border-color: darken($gray-9, 10%);
	}

}

.btn {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				color: $white;
			}

		}

	}

}

.btn-link {
	color: $primary;
	padding: 0;

	&:hover {
		color: $gray-9;
		text-decoration: none;
		outline: none;
	}

	&:focus {
		color: $gray-9;
		text-decoration: none;
		outline: none;
	}

}

.btn.btn-link:not(:disabled):not(.disabled):active:focus {
	color: $gray-9;
}

.btn-white {
	background: $white;
	border-color: $white;
	color: $gray-9;

	&:hover {
		background: darken($white, 10%);
		border-color: darken($white, 10%);
	}

	&:active {
		background: darken($white, 10%);
		border-color: darken($white, 10%);
	}

	&:focus {
		background: darken($white, 10%);
		border-color: darken($white, 10%);
	}

}

.btn.btn-white:not(:disabled):not(.disabled):active:focus {
	color: $white;
}

.btn-light {
	background: $gray-1;

	&:hover {
		background: darken($gray-1, 10%);
		border-color: darken($gray-1, 10%);
	}

	&:active {
		background: darken($gray-1, 10%);
		border-color: darken($gray-1, 10%);
	}

	&:focus {
		background: darken($gray-1, 10%);
		border-color: darken($gray-1, 10%);
	}

}

.btn.btn-light:not(:disabled):not(.disabled):active:focus {
	color: $white;
}

.btn.btn-sm {
	padding: 12px 24px;
}

.btn.btn-lg {
	padding: 17px 64px;
	font-size: 18px;
}

.btn-app {
	text-align: start;
	display: flex;
	align-items: center;

	i {
		font-size: 40px;
		margin-right: 10px
	}

}

/* btn-outline-primary */
.btn-outline-primary {
	border-color: $primary;
	color: $primary;

	&:hover {
		border-color: $primary;
		color: $white;
		background-color: $primary;
	}

	&:active {
		border-color: $primary;
		color: $white;
		background-color: $primary;
	}

	&:focus {
		border-color: $primary;
		color: $white;
		background-color: $primary;
	}

}

.btn-outline-dark {
	border-color: $gray-9;
	color: $gray-9;

	&:hover {
		border-color: $gray-9;
		color: $white;
		background-color: $gray-9;
	}

	&:active {
		border-color: $gray-9;
		color: $white;
		background-color: $gray-9;
	}

	&:focus {
		border-color: $gray-9;
		color: $white;
		background-color: $gray-9;
	}

}

/* Group Button */
.btn-group .btn:after {
	content: none;
}

.icon-btn {
	display: flex;
	width: 40px;
	height: 40px;
	text-align: center;
	background: $primary;
	border-radius: 50%;
	align-items: center;
	align-self: center;
	justify-content: center;
	color: $white;
}

.icon-btn {
	opacity: 1;
}

.icon-btn.btn-animation {
	opacity: 1;
}

.icon-btn-lg {
	width: 80px;
	height: 80px;
}

.icon-btn-md {
	width: 60px;
	height: 60px;
}

.icon-btn-sm {
	width: 40px;
	height: 40px;
}

.icon-btn-all-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.btn-animation:before {
	content: "";
	position: absolute;
	z-index: -9;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: block;
	width: 50px;
	height: 50px;
	background: $primary;
	border-radius: 50%;
	animation: pulse-border 1500ms ease-out infinite;
}

.btn-animation.icon-btn-lg:before {
	width: 80px;
	height: 80px;
}

.btn-animation.icon-btn-md:before {
	width: 70px;
	height: 70px;
}

.video-btn.btn-animation {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.video-btn-02 {
	a {
		display: flex;
		align-items: center;

		span {
			font-weight: 600;
		}

	}

	.btn-animation {
		position: inherit;
		transform: translate(0%, 0%);
	}

}

/* keyframes */
@keyframes pulse-border {
	0% {
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		opacity: 1;
	}

	100% {
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		opacity: 0;
	}

}

@keyframes sonarWave {
	from {
		opacity: 0.4;
	}

	to {
		transform: scale(3);
		opacity: 0;
	}

}

@media (max-width:991px) {
	.btn:after {
		margin-left: 22px;
	}

}
