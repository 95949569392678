/*****************************
  Shortcodes
*****************************/

/*****************************
  Accordion
*****************************/

.accordion {
	.accordion-item {
		border: none;
		margin-bottom: 10px;

		.accordion-collapse {
			border-width: 1px;
		}

	}

	.accordion-body {
		padding: 35px 35px 35px 42px;
	}

	.accordion-header {
		background: none;
		border-bottom: none;
		padding: 0;
	}

	>.accordion-item {
		.accordion-header {
			margin-bottom: 10px;
		}

		&:last-child {
			.accordion-header {
				margin-bottom: 0;
			}

		}

	}

	.accordion-header {
		.accordion-button {
			display: flex;
			background: $gray-1;
			font-size: 18px;
			color: $gray-8;
			padding: 22px 30px 22px 42px;
			width: 100%;
			text-align: left;
			border: none;
			font-weight: normal;
			position: relative;
			border-radius: $border-radius;
			font-weight: 500;

			&:focus {
				box-shadow: none;
				border-color: $border-color;
			}

		}

		.btn {
			&:after {
				content: "\f062";
				margin-left: auto;
				transform: rotate(0deg);
				font-family: "Font Awesome 5 Free";
				font-weight: bold;
			}
			&:hover{
				transform: translateY(0px);
				box-shadow:none;
			}

		}

		.accordion-button:not(.collapsed) {
			background: $primary;
			color: $white;

			&:after {
				transform: rotate(180deg);
			}

		}

	}

}


.accordion-button::after {
    background-image: none !important;
}
