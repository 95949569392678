/*****************************
  Case Study
*****************************/
.case-study {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;

  .case-study-title {
    display: block;
    color: $gray-8;
    font-size: 18px;
    font-weight: 500;

    &:hover {
      color: $primary;
    }

  }

  .case-study-services {
    display: block;
    margin-bottom: 10px;
    font-weight: 400;
    margin-top: 10px;
  }

  .case-study-img {
    background-size: cover;
    min-height: 249px;
    border-radius: $border-radius;
    background-position: center center;
  }

  .case-study-img.case-study-lg {
    min-height: 530px;
  }

  .case-study-info {
    padding: 20px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    position: absolute;
    left: 15px;
    bottom: 15px;
    z-index: 2;
    opacity: 0;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    background: $white;

    p {
      max-height: 66px;
      overflow: auto;
    }

    .icon-btn {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }

  }

  &:hover {
    .case-study-info {
      opacity: 1;
    }

  }

  .case-study-lg {
    + {
      .case-study-info {
        p {
          max-height: 340px;
          overflow: auto;
        }

      }

    }

  }

}

/* style 02 */
.case-study-style-02 {
  position: relative;

  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    top: auto;
    background: rgba($gray-9, 0.85);
    content: "";
    transition: all 0.5s ease;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    &:before {
      opacity: 1;
      visibility: visible;
    }

  }

  .case-study-title {
    color: $white;
  }

  .case-study-info {
    background: transparent;
    bottom: 0;
    top: inherit;
    height: inherit;

    .icon-btn {
      bottom: 30px;
    }

    p {
      color: $white;
    }

  }

}

.clients-detail {
  margin: 0;
  padding: 10px 0px;

  li {
    padding-right: 100px;
    margin-top: 0px;

    &:last-child {
      padding-right: 0px;
    }

  }

}

.case-study-style-03 {
  overflow: hidden;

  .case-study-img {
    transition: transform .5s ease-in-out;
    overflow: hidden;
    border-radius: 0;
  }

  .case-study-info {
    width: inherit;
    position: relative;
    left: inherit;
    bottom: inherit;
    z-index: 2;
    opacity: inherit;
    border-radius: inherit;
    transform: inherit;
    background: $white;
    padding: 20px;
    text-align: left;
    margin: -30px 25px 20px 25px;
    box-shadow: $boxshadow-lg;
    background-color: $white;
    font-weight: 700;
    display: block;
    color: $gray-9;
    font-size: 18px;
    line-height: 1.2;
    border-radius: $border-radius;
  }

  &:hover {
    .case-study-info {
      bottom: inherit;
      height: 100%;
      transition: transform .5s ease-in-out;
    }

    .case-study-img {
      transform: scale(1.07);
      transition: transform .5s ease-in-out;
    }

  }

  .case-study-style-03 a {
    font-weight: 500 !important;
  }

  .case-study-services {
    color: $primary;
    font-size: 14px;
  }

}

/* Responsive View */
@media (max-width:1199px) {
  .clients-detail li {
    padding-right: 110px;
  }

}

@media (max-width:991px) {
  .case-study .case-study-info {
    padding: 20px;
  }

  .clients-detail li {
    padding-right: 50px;
  }

}

@media (max-width:767px) {
  .clients-detail li {
    padding-right: 50px;
    width: 50%;
    float: left;
  }

}

@media (max-width:575px) {
  .clients-detail li {
    padding-right: 0px;
    width: 100;
    float: none;
  }

}
