/*************************
       Blog
*************************/
.blog-post {
	position: relative;

	.blog-post-image {
		position: relative;
		transition: all .5s ease;

		img {
			border-radius: $border-radius;
		}

	}

	.blog-post-content {
		padding: 20px 20px 0;

		.blog-post-info {
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			.btn {
				padding: 5px 12px;
			}

			.btn-round {
				&:before {
					width: 30px;
				}

				&:hover {
					&:before {
						width: 100%;
					}

				}

			}

		}

		.blog-post-date {
			padding-left: 15px;

			a {
				color: rgba($gray-8, 0.5);

				&:hover {
					color: $primary;
				}

			}

		}

		.blog-post-details {
			.blog-post-title {
				a {
					&:hover {
						color: $primary;
					}

				}

			}

		}

	}

}

.blog-post {
	.blog-post-content {
		.blog-post-details {
			.blog-post-meta {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				padding: 5px 0;

				a {
					padding: 0 15px;
					border-right: 1px solid $gray-1;
					color: $gray-3;
					font-weight: 500;

					&:first-child {
						padding-left: 0;
						margin-left: 0px;
					}

					&:hover {
						color: $primary;
					}

				}

			}

		}

	}

}

/*************************
       Blog style 02
*************************/
.blog-post-02 {
	position: relative;
	background: $white;
	box-shadow: $boxshadow-lg;

	.blog-post-image {
		position: relative;
		transition: all .5s ease;
	}

	.blog-post-content {
		padding: 40px 25px 60px 30px;

		.blog-post-info {
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			.btn {
				padding: 5px 12px;
				color: $gray-9 !important;

			}

			.btn-round {
				&:before {
					width: 30px;
					background: $primary;
				}

				&:hover {
					&:before {
						width: 100%;
						background: $primary;
					}

				}

			}

		}

		.blog-post-date {
			padding-left: 15px;

			a {
				color: rgba($gray-8, 0.5);

				&:hover {
					color: $primary;
				}

			}

		}

		.blog-post-details {
			.blog-post-title {
				a {
					&:hover {
						color: $primary;
					}

				}

			}

		}

		.blog-arrow {
			i {
				position: absolute;
				left: 43%;
				right: 0;
				bottom: -20px;
				text-align: center;
				width: 50px;
				height: 50px;
				transition: all .5s ease;
				&:hover{
					color: $gray-9;
				}
			}

		}

	}

}

.blog-post-02 {
	.blog-post-content {
		.blog-post-details {
			h5 {
				font-size: 22px;
				margin-top: 15px;
				line-height: 1.3;
			}

			.blog-post-meta {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				padding: 5px 0;

				a {
					padding: 0 15px;
					border-right: 1px solid $gray-1;
					color: $gray-3;
					font-weight: 500;

					&:first-child {
						padding-left: 0;
					}

					&:hover {
						color: $primary;
					}

				}

			}

		}

	}

}

.blog-post-02 {
	.blog-post-footer {
		border-top: 1px solid $gray-1;
		padding: 30px 0px 0px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.blog-post-time,
		.blog-post-author,
		.blog-post-comment,
		.share-box {
			margin: 0 5px;
		}

		.blog-post-author img {
			margin: 0 5px;
			height: 20px;
			border-radius: 50%;
		}

		span {
			font-size: 13px;
		}

		a {
			font-size: 13px;
			color: $gray-9;

			&:hover {
				color: $gray-8;
			}

		}

		i {
			padding-right: 5px;
		}

	}

}

.blog-post {
	.blog-post-footer {
		padding: 0px 0px 20px 0px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.blog-post-time,
		.blog-post-author,
		.blog-post-comment,
		.share-box {
			margin: 0px 8px 0px 0px;
		}

		.blog-post-author img {
			margin: 0 5px;
			height: 20px;
			border-radius: 50%;
		}

		span {
			font-size: 13px;
		}

		a {
			font-size: 13px;
			color: $gray-9;

			&:hover {
				color: $gray-8;
			}

		}

		i {
			padding-right: 5px;
		}

	}

}

/* blog post sticky */
.blog-post-sticky {
	.blog-post-image {
		position: relative;

		&:before {
			content: "\f005";
			position: absolute;
			top: 7px;
			right: 5px;
			z-index: 2;
			font-family: "Font Awesome 5 free";
			color: $white;
			font-size: 14px;
			font-weight: 900;
			line-height: normal;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 0;
			height: 0;
			z-index: 1;
			border-style: solid;
			border-width: 0 45px 45px 0;
			border-color: transparent $primary transparent transparent;
		}

	}

}

.post-navigation {
	.nav-previous {
		display: inline-block;

		span {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			width: 230px;
			display: inline-block;
		}

	}

	.nav-next {
		display: inline-block;
		position: relative;

		span {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			width: 230px;
			display: inline-block;
		}

	}

}

.pagination {
	margin-bottom: 0;
	justify-content: center;
}

.comments .media-body p:last-child {
	margin-bottom: 0;
}

/* Blog sidebar */
.blog-sidebar {
	.widget {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}

		.widget-title {
			margin-bottom: 0;
		}

		.search {
			position: relative;
			float: inherit;
			width: 100%;

			input {
				padding-right: 64px;
				height: 50px;
				padding-left: 20px;
			}

			i {
				position: absolute;
				right: 0;
				top: 0;
				background: $primary;
				color: $white;
				padding: 18px 20px;
				cursor: pointer;
				margin: 0;
				border-radius: 0 $border-radius $border-radius 0px;
			}

		}

		ul.list-style {
			li {
				margin-bottom: 10px;

				a {
					display: flex;
					color: $gray-3;
					width: 100%;

					&:hover {
						color: $primary;
					}

				}

				&:last-child {
					margin-bottom: 0;
				}

			}

		}

		.gallery {
			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					flex: 0 0 19%;
					margin: 0 1% 1% 0;

					&:nth-child(5n+5) {
						margin-right: 0;
					}

				}

			}

		}

		.social {
			ul {
				li {
					padding: 10px 10px 10px 20px;
					border: 1px solid $border-color;
					display: flex;
					margin-bottom: 10px;
					align-items: center;

					a {
						color: $gray-8;
						text-transform: capitalize;

						i {
							width: 15px;
						}

					}

					.follow {
						background: $gray-1;
						padding: 3px 18px;
						font-size: 12px;
						border-radius: $border-radius;

						&:hover {
							background: $gray-8;
							color: $white;
						}

					}

					&:last-child {
						margin-bottom: 0;
					}

				}

				.facebook {
					a {
						color: #466ca9;
					}

					.follow {
						&:hover {
							background: #466ca9;
						}

					}

				}

				.twitter {
					a {
						color: #20b5e6;
					}

					.follow {
						&:hover {
							background: #20b5e6;
						}

					}

				}

				.youtube {
					a {
						color: #d92c20;
					}

					.follow {
						&:hover {
							background: #d92c20;
						}

					}

				}

				.linkedIn {
					a {
						color: #13799f;
					}

					.follow {
						&:hover {
							background: #13799f;
						}

					}

				}

			}

		}

		.popular-tag {
			ul {
				li {
					display: inline-block;
					margin-bottom: 4px;

					a {
						padding: 6px 20px;
						display: block;
						border: 1px solid $border-color;
						color: $gray-3;

						&:hover {
							color: $primary;
							border-color: $primary;
						}

					}

				}

			}

		}

	}

}

.blog-detail {
	.media {
		display: flex;
		align-items: flex-start;
	}

}

/*************************
       Responsive
*************************/
@media (max-width:767px) {
	.post-navigation .nav-previous {
		display: block;
		text-align: center;
	}

	.post-navigation .nav-next {
		display: block;
		text-align: center;
		float: none;
	}

}

@media (max-width:575px) {
	.blog-post-meta {
		display: block;
	}

	.blog-post-meta a {
		display: inline-block;
		margin-bottom: 5px;
	}

	.post-navigation .nav-previous span,
	.post-navigation .nav-next span {
		width: 150px;
	}

}
