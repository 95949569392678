/*****************************
  banner
*****************************/
.banner h1 {
    color: $white;
    font-size: 72px;
    line-height: 82px;
    margin-bottom: 20px;
}

.banner h6 {
    color: $white;
    margin-bottom: 30px;
}

.swiper-button-prev,
.swiper-button-next {
    background-image: none;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
}

.swiper-button-prev {
    left: 10%;
}

.swiper-button-next {
    right: 10%;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    opacity: 1;
}

.banner ul {
    padding: 0;
    margin: 30px 0 50px;
    list-style-type: none;
    text-align: center;
}

.banner ul li {
    display: inline-block;
    position: relative;
    margin-right: 6px;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        height: 6px;
        width: 6px;
        top: 50%;
        margin-top: -3px;
        background: $primary;
        border-radius: 10px;
    }

}

.banner ul li a {
    color: $white;
    font-size: 18px;
    font-weight: 400;
    padding: 0 14px;
}

.banner ul li a:hover {
    color: $primary;
}

.banner ul li:last-child a {
    border-right: 0;
}

/* banner 02 */
.slider-02 .font-large {
    font-size: 86px;
    line-height: 100px;
}

.text-stroke-white {
    color: transparent !important;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $white;
    display: block;
}

.text-stroke-primary {
    color: transparent !important;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $primary;
    display: block;
}

.text-stroke-dark {
    color: transparent !important;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $gray-9;
    display: block;
}

/* banner 03 */
.banner-logo {
    padding: 10px 20px;
    background: $primary;
    flex: 0 0 240px;
    border-radius: 7px;
    color: $white;
    font-size: 70px;
    line-height: 60px;
    position: relative;
    align-self: center;
    margin-left: 20px;
    text-align: center;
    display: inline-block;
}

.banner-logo:before {
    position: absolute;
    content: "";
    width: 30px;
    height: 21px;
    left: -30px;
    border-top: 20px solid rgba($primary, 0);
    border-right: 20px solid $primary;
    top: 15px;
}

.banner {
    .swiper-button-big {
        .icon-btn {
            font-size: 100px;
            background: transparent;
            font-weight: 500;
        }

    }

}

/*****************************
  banner-style-02
*****************************/
.banner.banner-style-02 {
    h1 {
        font-weight: 700;
        letter-spacing: 10px;
        text-transform: uppercase;
    }

}

.video-style-01 {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;

    .popup-icon {
        display: block;

        i {
            font-size: 24px;
            width: 80px;
            height: 80px;
            justify-content: center;
            align-items: center;
            padding-left: 5px;
            background: $primary;
            border-radius: $border-radius-50;
            border: none;
            color: $white;
            display: flex;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 20px;
        }

    }

}

/*************************
       Responsive
*************************/
@media (max-width:1200px) {
    .banner h1 {
        font-size: 52px;
        line-height: 62px;
        margin-bottom: 20px;
    }

    .slider-02 .font-large {
        font-size: 52px;
        line-height: 62px;
    }

}

@media (max-width:991px) {
    .banner h1 {
        font-size: 46px;
        line-height: 52px;
        margin-bottom: 20px;
    }

    .slider-02 .font-large {
        font-size: 46px;
        line-height: 52px;
    }

    .swiper-button-prev {
        left: 5%;
    }

    .swiper-button-next {
        right: 5%;
    }

}

@media (max-width:767px) {
    .banner h1 {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 30px;
    }

    .slider-02 .font-large {
        font-size: 30px;
        line-height: 36px;
    }

    .banner-logo {
        width: auto;
        font-size: 50px;
        line-height: 50px;
    }

}
