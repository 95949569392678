/*****************************
    Footer
*****************************/
.footer {
  .container-fluid {
    padding: 0 100px;
  }

  .footer-link {
    display: flex;

    ul {
      padding-right: 50px;

      &:last-child {
        padding-right: 0px;
      }

      li {
        a {
          color: $gray-6;
          font-weight: 500;
          padding: 7px 0px;
          display: block;
        }

        &:hover {
          a {
            color: $primary;
          }

        }

        &:first-child {
          a {
            padding-top: 0;
          }

        }

        &:last-child {
          a {
            padding-bottom: 0;
          }

        }

      }

    }

  }

/* Social icon */
  .social-icon {
    li {
      display: inline-block;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      a {
        font-size: 18px;
        color: $gray-9;
      }

      &:hover {
        a {
          color: $primary;
        }

      }

    }

  }

  .footer-contact-info {
    img {
      height: 30px;
    }

    p {
      color: $gray-6;
      font-weight: 500;
    }

    .flag-svg {
      height: 30px;
    }

  }
}

  .footer.bg-dark {
    .footer-link ul li a {
      color: $white;

      &:hover {
        color: $primary;
      }

    }

    .social-icon li {
      margin-right: 30px;

      a {
        color: $white;

        &:hover {
          color: $primary;
        }

      }

    }

  }
/*****************************
    Footer - 02
*****************************/
  ul.footer-contact {
    li {
      display: flex;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      .footer-contact-icon {
        font-size: 36px;
        color: $white;
        margin-right: 15px;
        opacity: 0.3;
      }

      .footer-contact-info {
        color: $white;

        span {
          color: $primary;
        }

        p {
          opacity: 0.6;
        }

      }

    }

  }

  .footer-newsletter {
    margin-bottom: 70px;

    h2 {
      font-size: 36px;
    }

    p {
      color: $white;
      opacity: 0.6;
      margin-bottom: 20px;
    }

    .form-inline {
      position: relative;

      .form-group {
        width: 100%;

        .form-control {
          width: 100%;
          padding-right: 210px;
        }

      }

      .btn-primary {
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 13px 35px 14px 35px;
        border-radius:0px 5px 5px 0px;

        &:hover {
          background: darken($primary, 10%);
          border-color: darken($primary, 10%);
          color: $white;
          transform: translateY(0px);
        }

      }

    }

  }

  .useful-links {
    ul {
      li {
        a {
          color: $white;
          line-height: 30px;
          opacity: 0.6;

          &:hover {
            color: $primary;
            opacity: 1;
          }

        }

      }

    }

  }

  .call-center {
    h2 {
      color: $white;
      font-size: 36px;
      margin-bottom: 0px;
      opacity: 0.6;
    }

    h3 {
      color: $white;
      font-size: 24px;
      opacity: 0.6;
    }

  }

  .footer-social-icon {
    margin-top: 100px;

    p {
      color: $white;
      font-size: 15px;
      opacity: 0.6;
    }

    ul {
      display: flex;
      margin-top: 20px;

      li {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }

        a {
          font-size: 24px;
          color: $white;

          &:hover {
            color: $primary;
          }

        }

      }

    }

  }

  .copyright {
    padding: 35px 0px 45px 0px;

    .footer-brand {
      img {
        height: 32px;
      }

    }

    a {
      color: $white;

      &:hover {
        color: $primary;
      }

    }

  }

/*************************
        Responsive
*************************/
  @media (max-width:991px) {
    .footer-social-icon {
      margin-top: 50px;
    }

    .footer-social-icon {
      margin-top: 50px;
    }

    .call-center {
      h2 {
        font-size: 28px;
      }

      h3 {
        font-size: 20px;
      }

    }

  }

  @media (max-width:767px) {
    .social-icon-lg li i {
      font-size: 24px;
    }

    .call-center {
      h2 {
        font-size: 24px;
      }

      h3 {
        font-size: 20px;
      }

    }

  }

  @media (max-width:575px) {
    .footer-link {
      display: block;

      ul {
        li {
          &:first-child {
            a {
              padding-top: 7px;
            }

          }

          &:last-child {
            a {
              padding-bottom: 7px;
            }

          }

        }

      }

    }

    /* Social icon */
    .social-icon {
      li {
        padding: 0 10px;
        margin-right: 8px;

        i {
          font-size: 18px;
        }

      }

    }

  }
