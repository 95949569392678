/*****************************
	Team
*****************************/
.team {
	text-align: center;
	position: relative;

	.team-img {
		img {
			border-radius: $border-radius-50;
		}

	}

	&:hover {
		.team-img {
			z-index: 5;
			position: relative;
		}

		.team-info {
			z-index: 5;
			position: relative;

			ul {
				opacity: 1;
				bottom: -35px;
			}

		}

		.team-bg {
			opacity: 1;
			z-index: 5;
		}

	}

	.team-info {
		padding: 20px 0px 0px;

		.team-name {
			color: $gray-8;
			font-size: 13px;
			font-weight: 600;

			&:hover {
				color: $primary;
			}

		}

		p {
			font-size: 11px;
			margin-bottom: 20px;
		}

		ul {
			display: flex;
			justify-content: center;
			margin-bottom: 0;
			position: absolute;
			bottom: -25px;
			left: 0;
			right: 0;
			opacity: 0;
			transition: all 0.3s ease-in-out;

			li {
				margin: 0 12px;

				a {
					color: $gray-8;

					&:hover {
						color: $primary;
					}

				}

			}

		}

	}

	.team-bg {
		background: $white;
		position: absolute;
		left: 0%;
		top: -10%;
		width: 100%;
		height: 130%;
		z-index: 4;
		opacity: 0;
		transition: all 0.3s ease-in-out;
		border-radius: $border-radius;
		box-shadow: $boxshadow-lg;
	}

}

.team.team-01 {
	text-align: center;
	position: relative;
	padding: 15px;
	position: relative;
	padding-bottom: 0;
	padding-top: 0;

	.team-img {
		img {
			border-radius: $border-radius;
		}

	}

	.team-info {
		a {
			font-size: 16px;
			font-weight: 500;
		}

	}

}

.team.team-02 {
	padding: 15px;
	padding-bottom: 0;
	padding-top: 0;
}

.team.team-02 .team-bg {
	left: 0%;
	top: -10%;
	width: 100%;
	height: 140%;
}

.apply-position {
	.team-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		background: $gray-8;
		padding: 0;
		border-radius: 3px;
		font-size: 110px;
		min-height: 173px;
		color: rgba($white, 0.1);
	}

	.btn {
		padding: 0;
		font-size: 13px;

		&:after {
			margin-left: 10px;
		}

	}

}

@media (max-width:575px) {
	.team .team-img img {
		width: 100%;
	}

}
