/*****************************
  Category
*****************************/

/* Transition */
.category-grid-style-01 .category-item .category-icon,
.category-grid-style-01 .category-item:before,
.category-list-style-01 ul,
ul.category-list li a:after,
.category-box .category-icon,
.category-box:hover .category-icon i,
.category-box:hover .category-icon .category-title,
.category-box-style-02,
.category-box-style-02:before,
.category-box-style-02 ul.category-list {
	transition: all 0.3s ease-in-out;
}

/* Grid Style 01 */
.category-grid-style-01 {
	flex-wrap: wrap;
	background: $white;
	border-radius: $border-radius;
	border: 1px solid rgba($border-color, 0.08);
	height: 100%;

	.category-item {
		height: 100%;
		padding: 30px;
		text-align: center;
		position: relative;
		cursor: pointer;
		border-radius: $border-radius;

		&:before {
			content: "";
			background: $primary;
			width: 110%;
			height: 110%;
			display: inline-block;
			position: absolute;
			left: -5%;
			top: -5%;
			z-index: 1;
			opacity: 0;
			transform: scale(0.96);
			border-radius: $border-radius;
		}

		&:hover {
			&:before {
				box-shadow: $boxshadow-lg;
				opacity: 1;
				transform: scale(1);
				z-index: 0;
			}

			.category-icon {
				color: $white;
			}

			.category-title {
				color: $white;
			}

		}

		.category-icon {
			color: $black;
			font-size: 44px;
			line-height: 1;
			margin-bottom: 10px;
			position: relative;
			z-index: 2;
		}

		.category-title {
			color: $gray-8;
			font-weight: 500;
			margin-bottom: 0;
			position: relative;
			z-index: 2;
			font-size: 16px;
		}

	}

	.show-more-cat {
		display: flex;
		justify-content: center;
		margin: 60px 0 -20px 0;
		width: 100%;

		a {
			color: $gray-8;
			font-size: 12px;
			font-weight: bold;

			&:hover {
				color: $primary;
			}

		}

		i {
			margin: 5px auto 0 auto;
		}

	}

}

/* Grid Style transparent  */
.category-grid-style-01.category-grid-transparent {
	background-color: inherit;

	.category-item {
		.category-icon {
			i {
				color: $primary;
			}

		}

		a {
			color: $white;
		}

		&:hover {
			.category-icon {
				i {
					color: $white;
				}

			}

		}

	}

}

.category-grid-style-01.category-border {
	border: 1px solid $gray-2;
}

/* Grid Style 02 */
.category-grid-style-02 {
	ul {
		display: flex;
		flex-wrap: wrap;
		margin: 0px -15px 0px -15px;

		li {
			display: flex;
			width: 25%;
			padding-right: 15px;
			padding-left: 15px;
			margin-bottom: 30px;

			a {
				flex: 0 0 100%;
				font-size: 18px;
				color: $gray-8;
				padding: 15px;
				border-bottom: 1px solid $border-color;
				background-color: $gray-1;
				display: flex;
				align-items: center;

				&:hover {
					color: $primary;
				}

			}

			&:nth-last-child(-n+4) {
				a {
					margin-bottom: 0;
				}

			}

		}

	}

}

/* List Style 01 */
.category-list-style-01 ul.category-list {
	margin-bottom: 0;
	padding: 40px 25px 15px 25px;
	height: 100%;
	background: rgba($white, 0.1);
	border-top: 5px solid $primary;
	border-radius: $border-radius;
}

.category-list-style-01 ul.category-list:hover {
	border-bottom-color: rgba($white, 0.3);
}

ul.category-list {
	padding-left: 0;
	margin-bottom: 0;

	li {
		list-style: none;
		margin-bottom: 15px;

		a {
			display: inline-block;
			color: $gray-3;

			&:hover {
				color: $primary;

				&:after {
					margin-left: 10px;
					opacity: 1;
				}

			}

			&:after {
				content: '\f061';
				font-family: "Font Awesome 5 Free";
				font-size: 12px;
				font-weight: bold;
				margin-left: -10px;
				opacity: 0;
			}

		}

	}

}

.dark-background {
	ul.category-list {
		li {
			a {
				color: $white;

				&:hover {
					color: $primary;
				}

			}

		}

	}

}

/* Category Box Style 01 */
.category-box {
	.category-icon {
		background: $white;
		padding: 40px 30px;
		margin-bottom: 30px;
		text-align: center;
		box-shadow: $boxshadow-lg;
		border-radius: $border-radius;

		i {
			color: $primary;
			font-size: 80px;
			margin-bottom: 20px;
		}

	}

	&:hover {
		.category-icon {
			background: $primary;

			i {
				color: $white;
			}

			.category-title {
				color: $white;
			}

		}

	}

	ul.category-list {
		li {
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}

		}

	}

	p {
		font-size: 16px;
		text-align: center;
		color: $black;
		margin-bottom: 0;
	}

}

.category-box.category-box-style-01 {
	.category-icon {
		text-align: left;
	}

	ul.category-list {
		text-align: left !important;
		padding: 0 20px;

		li {
			position: relative;
			border-bottom: 1px solid $border-color;
			padding-bottom: 15px;
			margin-bottom: 13px;

			&:last-child {
				padding-bottom: 0;
				border: 0;
			}

			a {
				display: block;

				&:after {
					content: '\f105';
					position: absolute;
					right: 0;
					opacity: 1;
				}

			}

		}

	}

}

.category-box-style-01.active {
	.category-icon {
		background: $primary;
		box-shadow: $boxshadow-lg;
		transform: scale(1);

		i {
			color: $white;
		}

		.category-title {
			color: $white;
		}

	}

	ul.category-list {
		opacity: 1;
	}

}

/* Category Box Style 02 */
.category-box-style-02 {
	padding: 60px 5px;
	border-radius: $border-radius;
	transform: scale(0.98);

	&:hover {
		background: $gray-9;
		box-shadow: $boxshadow-lg;
		transform: scale(1);

		.category-icon {
			background: transparent;
		}

		ul.category-list {
			opacity: 1;
		}

	}

	.category-icon {
		background: transparent;
		box-shadow: none;
		padding: 0;
	}

	ul.category-list {
		opacity: 0;

		li {
			a {
				color: $white;

				&:hover {
					color: $primary;
				}

			}

		}

	}

}

.category-box-style-02.active {
	background: $gray-9;
	box-shadow: $boxshadow-lg;
	transform: scale(1);

	.category-icon {
		background: transparent;

		i {
			color: $white;
		}

		.category-title {
			color: $white;
		}

	}

	ul.category-list {
		opacity: 1;
	}

}

/* category-style-03 */
.category-style-03 {
	display: flex;
	flex-wrap: wrap;

	a.category-item {
		padding: 40px 30px 25px 30px;
	}

}

/* Responsive View */
@media (max-width:991px) {
	.category-grid-style-01 {
		.category-item {
			margin-right: 2%;
			flex: 0 0 23.5%;
		}

	}

	.category-grid-style-01 .category-item:nth-child(4n+0) {
		margin-right: 0px;
	}

	.category-grid-style-01 .category-item:nth-child(6n+0) {
		margin-right: 2%;
	}

	.category-grid-style-01 .category-item:last-child {
		margin-right: 0px;
	}

	.category-box-style-02 {
		padding: 20px 5px;
	}

	.category-grid-style-02 {
		ul {
			li {
				width: 33.3333%;
				margin-bottom: 35px;

				a {
					padding-bottom: 25px;
				}

			}

		}

	}

}

@media (max-width:767px) {
	.category-grid-style-01 {
		.show-more-cat {
			margin-top: 40px;
		}

	}

	.category-grid-style-02 {
		ul {
			li {
				width: 50%;
				margin-bottom: 25px;

				a {
					padding-bottom: 15px;
				}

			}

		}

	}

}

@media (max-width:575px) {
	.category-grid-style-01 {
		.category-item {
			width: 100%;
		}

	}

	.category-grid-style-02 {
		ul {
			li {
				width: 100%;
				margin-bottom: 15px;
			}

		}

	}

	.category-grid-style-01.category-grid-transparent {
		display: block;
	}

}
