/*****************************
    Testimonial
*****************************/
.testimonial {
    .owl-item {
        padding: 0 2%;
    }

    .testimonial-item {
        .testimonial-avatar {
            margin-bottom: 40px;
            background: $white;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px auto 40px;
        }

        .testimonial-content {
            margin-bottom: 30px;

            p {
                font-size: 26px;
                color: $gray-8;
                line-height: 1.4;
            }

        }

        .testimonial-author {
            .testimonial-name {
                h6 {
                    font-weight: 700;
                }

            }

        }

    }

}

.row-background {
    .testimonial-item {
        .testimonial-content {
            p {
                color: $white;
            }

        }

        .testimonial-author {
            color: $white;

            .testimonial-name {
                h6 {
                    color: $primary;
                }

            }

        }

    }

}

/* style 02 */
.testimonial-style-02 {
    box-shadow: $boxshadow-lg;
    border-radius: $border-radius;

    .testimonial-item {
        padding: 0 15%;
        margin-top: -70px;
        position: relative;
        z-index: 9;

        .testimonial-content {
            p {
                font-size: 18px;
                color: $black;
                font-style: italic;
            }

        }

        .testimonial-author {
            margin-bottom: 20px;
        }

        .testimonial-avatar {
            width: 120px;
            height: 120px;
            margin: 0 auto;
        }

    }

    .item {
        .svg-item {
            svg {
                position: absolute;
                bottom: 0px;
                left: 0;
                width: 100%;
                height: auto;
            }

        }

    }

    /* testimonial-item-sm */
    .testimonial-item-sm {
        padding: 0 15%;
        margin-top: -50px;
        position: relative;
        z-index: 9;

        .testimonial-content {
            p {
                font-size: 18px;
                color: $black;
                font-style: italic;
            }

        }

        .testimonial-avatar-sm {
            width: 90px;
            height: 90px;
            margin: 0 auto;
        }

    }

}

.testimonial-style-02.owl-carousel {
    .owl-nav {
        .owl-next {
            position: relative;
            top: 0;
            transform: inherit;
            right: 0;
        }

        .owl-prev {
            position: relative;
            top: 0;
            transform: inherit;
            left: 0;
        }

        width: auto;
        display: inline-block;
        position: relative;
        bottom: -20px;
        box-shadow: $boxshadow-lg;
        padding: 8px 10px;
        border-radius: $border-radius;
        background: $white;

        i {
            font-size: 14px;
            padding: 0 10px;
        }

    }

    .testimonial-img {
        position: relative;
    }

}

.video-image {
    position: relative;

    img {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
    }

}

/*****************************
    testimonial-02-small
*****************************/
.testimonial-02-small {
    .testimonial-content {
        font-size: 14px;
        padding: 60px 40px 20px;

        .quotes {
            font-size: 20px;
            top: 26px;
        }

    }

}

@media (max-width:575px) {
    .testimonial .testimonial-item {
        padding: 26px;
    }

    .testimonial .testimonial-item .testimonial-content {
        font-size: 18px;
    }

}

/*****************************
    testimonial-style-03
*****************************/
.testimonial-style-03 {
    position: relative;

    .testimonial-content {
        .quotes {
            position: absolute;
            font-size: 40px;
            z-index: 0;
            color: $white;
            right: 20px;
            bottom: 20px;
        }

        .testimonial-des {
            font-size: 18px;
            margin-top: 10px;
        }

    }

}

/*****************************
    testimonial-style-04
*****************************/
.testimonial-style-04 {
    padding: 70px 0px;
    text-align: center;
    position: relative;
    margin: 0 30px;

    .testimonial-info {
        padding: 70px 40px;
        box-shadow: 5px 5px 24px 0px rgba(2, 45, 98, 0.1);
        border-radius: $border-radius;
        position: relative;
        background-color: $white;

        .testimonial-quote {
            position: absolute;
            top: -75px;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;

            i {
                font-size: 90px;
                color: rgba($gray-8, 0.1);
            }

        }

        .testimonial-content {
            font-size: 18px;
            position: relative;
            margin-bottom: 50px;
        }

        .testimonial-author {
            margin-bottom: -150px;

            .avatar {
                position: inherit;
                top: 0px;
                left: inherit;
                margin-left: 0;
                margin-bottom: 20px;
                z-index: 99;

                img {
                    border-radius: $border-radius;
                }

            }

            .testimonial-name {
                h6 {
                    font-weight: 400;
                    margin-right: 10px;
                    display: inline-block;
                }

                span {
                    font-size: 16px;
                    font-weight: 400;
                    color: $gray-2;
                }

            }

        }

    }

}

/*****************************
    testimonial-style-04
*****************************/
.testimonial-style-04.testimonial-transparent {
    .testimonial-info {
        background-color: rgba($white, 0.1);

        .testimonial-quote {
            i {
                color: $primary;
            }

        }

        .testimonial-content {
            color: $white;
        }

        .testimonial-author {
            .avatar {
                img {
                    border-radius: $border-radius-50;
                }

            }

            .testimonial-name {
                h6 {
                    color: $white;
                }

                span {
                    color: $primary !important;
                }

            }

        }

    }

}

@media (max-width:991.98px) {
    .testimonial-item .testimonial-content p {
        font-size: 20px;
    }

    .testimonial {
        .owl-item {
            padding: 0 2%;
        }

    }

}

@media (max-width:767.98px) {
    .testimonial-item .testimonial-avatar {
        margin-bottom: 20px;
    }

    .testimonial-item .testimonial-content {
        margin-bottom: 20px;
    }

    .testimonial-item .testimonial-content p {
        font-size: 16px;
    }

    .testimonial-style-02 .testimonial-item {
        padding: 0 5%;
    }

    .testimonial-style-02 .item .svg-item {
        display: none;
    }

    .popup-icon i {
        width: 50px;
        height: 50px;
        font-size: 20px;
    }

}

@media (max-width:575.98px) {
    .testimonial-style-02 .testimonial-item {
        margin-top: 20px;
    }

    .testimonial-style-02 .testimonial-item .testimonial-avatar {
        width: 80px;
        height: 80px;
    }

}
