/*****************************
  List style
*****************************/
.list {
	margin: 0;
	padding: 0;

	li {
		margin-bottom: 15px;
		color: $black;

		&:last-child {
			margin-bottom: 0px;
		}

	}

}

.list-number {
	li {
		span {
			background: rgba($primary, 1);
			color: $white;
			border-radius: $border-radius-50;
			margin-right: 8px;
			width: 25px;
			height: 25px;
			font-weight: 600;
			text-align: center;
			display: inline-block;
			line-height: 25px;
			font-size: 13px;
		}

	}

}

.list.list-01 {
	margin: 0;
	padding: 0;

	li {
		margin-bottom: 15px;
		color: $black;

		&:last-child {
			margin-bottom: 0px;
		}

		i {
			background-color: rgba($primary, 0.1);
			width: 40px;
			height: 40px;
			border-radius: 50%;
			color: $primary;
		}

		span {
			margin-left: 15px;
		}

	}

}
