/*****************************
  Listing
*****************************/
.list {
	margin: 0;
	padding: 0;

	li {
		margin-bottom: 15px;
		color: $black;

		&:last-child {
			margin-bottom: 0px;
		}

	}

}

.grid-list li {
	padding-bottom: 25px;
	border-bottom: 2px solid $border-color;
	margin-bottom: 50px;
}

.sidebar {
	margin-bottom: 20px;

	.widget {
		margin-bottom: 20px;
		background: $white;
		box-shadow: 0 1px 2px rgba($black, 0.1);

		.form-group {
			.btn {
				padding: 12px 19px;
			}

		}

	}

	.widget-title {
		padding: 14px 20px;

		i {
			padding-right: 8px;
		}

	}

	.widget-content {
		background: $white;
		padding: 20px;

		.collapse-title {
			font-size: 16px;
			font-weight: 500;
			color: $gray-8;
			margin-bottom: 10px;
			display: flex;

			i {
				margin-left: auto;
				color: $gray-2;
			}

		}

		.collapse-title-icon-left {
			color: $gray-8;
			display: block;

			i {
				margin-left: inherit;
				padding-right: 6px;
			}

		}

	}

	.widget-car-body {
		ul {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 0;

			li {
				flex: 2;
				min-width: 50%;
				margin-bottom: 10px;

				a {
					color: $gray-3;

					span {
						margin-top: 4px;
						display: block;
					}

					&:hover {
						color: $primary;
					}

				}

			}

		}

	}

	.widget-contact {
		ul {
			margin-top: 20px;

			li {
				margin-bottom: 10px;

				i {
					color: $primary;
					width: 20px;
					margin-right: 10px;
				}

			}

		}

	}

}

.sidebar.services-sidebar {
	.widget {
		.widget-content {
			padding: 30px;

			ul {
				li {
					border-bottom: 1px solid $gray-1;
					padding-bottom: 20px;
					padding-top: 5px;

					&:last-child {
						border-bottom: none;
						padding-bottom: 0px;
					}

				}

			}

		}

	}

}
