/*****************************
  Newsletter
*****************************/
.newsletter {
	position: relative;

	.btn {
		position: absolute;
		right: 0;
		line-height: 20px;
		top: 0;
		padding: 14px 30px;
	}

}
