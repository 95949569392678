/*****************************
  Client
*****************************/
.our-clients {
    position: relative;
    display: flex;
    align-items: center;

    .client-title {
        flex: 0 0 16%;

        h5 {
            position: relative;
            z-index: 9;
            margin-bottom: 0;
        }

    }

    .svg-item {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        overflow: hidden;
    }

    .brand-logo {
        flex: 0 0 84%;
        overflow: hidden;
        padding-right: 30px;
    }

    .client-btn {
        flex: 0 0 20%;
    }

}

/* style 02 */
.our-clients-style-02 {
    border-radius: $border-radius;
    padding: 50px 0;

    .svg-item {
        border-radius: $border-radius;
        width: 100%;
        height: 100%;

        &:before {
            position: absolute;
            left: -49px;
            width: 200px;
            height: 200px;
            background: $gray-1;
            content: "";
            top: -30px;
            border-radius: 100%;
        }

    }

}

@media (max-width:1199px) {
    .our-clients-style-02 {
        display: block;
        padding: 0;

        .client-title {
            padding-left: 20px;
            padding: 20px;
            background: $primary;
        }

        .svg-item {
            display: none;
        }

        .brand-logo {
            padding: 40px 20px;
        }

        .client-btn {
            padding-left: 20px;
            padding-bottom: 20px;
        }

    }

}
